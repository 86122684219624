import { PageProps } from "@/types";
import MetaHead from "@/Pages/MetaHead/Metahead";
import { EventProvider } from "@/context/EventContext";
import Events from "@/Pages/Events/List/Events";
import { usePage } from '@inertiajs/react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Modal, Paper, Typography } from '@mui/material';
import { useState, useRef } from 'react';

export default function EventsPage({ events,types,categories,periferiakes_enotites,seo }: PageProps) {
    const position = [51.505, -0.09]
    const { flash } = usePage().props
    const [openModal, setOpenModal] = useState(flash?.message ? true: false);

    const handleCloseModal = () => {
        setOpenModal(false);
      };
    
      const handleOpenModal = () => {
        setOpenModal(true);
    
        setTimeout(() => {
          handleCloseModal();
        }, 9000);
      };
    

    return (
        <EventProvider events={events} types={types} categories={categories} periferiakes_enotites={periferiakes_enotites} >
            <MetaHead seo={seo}/>




            {flash?.message && (
                
                 <Modal open={openModal} onClose={handleCloseModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                 <Paper style={{ width: 350, padding: 16, position: 'relative', border:  '2px solid red' }}>
              <div style={{ display: 'flex', alignItems: 'center', }}>
                <ErrorOutlineOutlinedIcon style={{ color: "red" }} />
                <Typography variant="body1" style={{ marginLeft: 8 }}>
                  {flash?.message}
                </Typography>
                <CloseIcon
                  onClick={handleCloseModal}
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                />
              </div>
              </Paper>
              </Modal>
            )}


            <Events/>
        </EventProvider>
    );
}
